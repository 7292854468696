@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Poppins", Arial, sans-serif;
  }
}

body {
  margin: 0;
  width: 100vw;
  height: 100vh;
}

#root {
  height: 100%;
  width: 100%;
}

.cyber-background {
  background-image: url("/public/background.jpg");
  background-color: blue;
  background-size: cover;
  background-position: center;
}
